<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg
      v-if="exceptionMsg"
      :msg="exceptionMsg"
      :image="exceptionImg"
    />

    <div v-if="grants.grantsData != undefined && grants.grantsData.length != 0">
      <GrantsTable
        v-on:setGrant="grants.grant.fillData($event)"
        :grants="grants.grantsData"
        :pagination="grants.pagination"
      />

      <Pagination
        v-if="!isLoading"
        :paginationData="grants.pagination"
        v-on:changePage="changePage"
      />
      <GrantInfo :grant="grants.grant" />
      <GrantDelete :grant="grants.grant" v-on:refresh="getAllGrants()" />
    </div>

    <GrantBtns
      :theFilterData="grants.filterData"
      v-on:search="search($event)"
    />
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import GrantsTable from "@/components/settings/settingsOther/grants/GrantsTable.vue";
import GrantInfo from "@/components/settings/settingsOther/grants/GrantInfo.vue";
import GrantDelete from "@/components/settings/settingsOther/grants/GrantDelete.vue";
import GrantBtns from "@/components/settings/settingsOther/grants/GrantBtns.vue";
import Pagination from "@/components/general/Pagination.vue";
import Grants from "@/models/settings/settingsOther/grants/Grants";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    GrantsTable,
    GrantInfo,
    GrantDelete,
    GrantBtns,
    Pagination,
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken,
      language: localStorage.getItem("userLanguage") || "ar",
      grants: new Grants(),
    };
  },
  methods: {
    changePage(page) {
      this.grants.pagination.selfPage = page;
      this.getAllGrants();
    },
    search(data) {
      this.grants.filterData.fillData(data);
      this.grants.grantsData = [];
      this.getAllGrants();
    },
    async getAllGrants() {
      this.isLoading = true;
      try {
        const response = await this.grants.grant.getAllGrants(
          this.language,
          this.userAuthorizeToken,
          this.grants.pagination,
          this.grants.filterData
        );

        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.grants.fillData(response.data);
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.grants.grantsData = [];
          this.exceptionImg = "";
          this.exceptionMsg = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.grants.grantsData = [];
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.grants.grantsData = [];
          this.exceptionImg = "illustrator-api-catch.svg";
          this.exceptionMsg = response.data.msg;
        }
      } catch (error) {
        this.grants.grantsData = [];
        this.exceptionImg = "illustrator-somethingWrong.svg";
        this.exceptionMsg = this.$t("errorCatch");
      }
      this.isLoading = false;
    },
  },
  computed: {},
  async created() {
    this.getAllGrants();
  },
};
</script>
