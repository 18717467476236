import PaginationData from "@/models/general/PaginationData";
import Grant from "./Grant";
import GrantsFilter from "./GrantsFilter";

export default class Grants {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.status = 0;
    this.msg = "";
    this.pagination = new PaginationData();
    this.grantsData = [];
    this.grant = new Grant();
    this.filterData = new GrantsFilter();
  }
  fillData(data) {
    this.status = data.status;
    this.msg = data.msg;
    this.pagination.fillData(data.grantsPagination);
    this.grantsData = data.grantsPagination.grantsData;
  }
}
