<template>
  <div v-if="grants" class="">
    <div class="table-container">
      <table class="my-table">
        <thead>
          <tr>
            <th>#</th>
            <th>{{ $t("Grants.name") }}</th>
            <th>{{ $t("Grants.description") }}</th>
            <th>{{ $t("notes") }}</th>
            <th>{{ $t("info") }}</th>
            <th
              v-if="
                checkPrivilege(
                  usersEstablishmentPrivilegeData
                    .usersPrivilegAdditionalSettings.additionalSettingsUpdate
                )
              "
            >
              {{ $t("edit") }}
            </th>
            <th
              v-if="
                checkPrivilege(
                  usersEstablishmentPrivilegeData
                    .usersPrivilegAdditionalSettings.additionalSettingsArchive
                )
              "
            >
              {{ $t("delete") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(Grant, index) in grants" :key="index">
            <td>{{ ++index + pagination.currentIndex }}</td>
            <td>
              {{ isDataExist(Grant.grantNameCurrent) }}
            </td>
            <td>
              {{ isDataExist(Grant.grantDescriptionCurrent) }}
            </td>
            <td>
              {{ isDataExist(Grant.grantNotes) }}
            </td>
            <td>
              <button
                v-b-modal.GrantInfo
                class="btn p-0"
                :title="$t('info')"
                @click="setGrant(Grant)"
              >
                <img src="@/assets/images/info.svg" class="icon-lg" />
              </button>
            </td>
            <td
              v-if="
                checkPrivilege(
                  usersEstablishmentPrivilegeData
                    .usersPrivilegAdditionalSettings.additionalSettingsUpdate
                )
              "
            >
              <router-link
                :to="{
                  name: 'GrantEdit',
                  params: {
                    grantToken: Grant.grantToken,
                  },
                }"
                :title="$t('edit')"
              >
                <img src="@/assets/images/pencil.svg" class="icon-lg" />
              </router-link>
            </td>
            <td
              v-if="
                checkPrivilege(
                  usersEstablishmentPrivilegeData
                    .usersPrivilegAdditionalSettings.additionalSettingsArchive
                )
              "
            >
              <button
                v-b-modal.GrantDelete
                class="btn p-0"
                :title="$t('delete')"
                @click="setGrant(Grant)"
              >
                <img src="@/assets/images/trash.svg" class="icon-lg" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import privilegeMixin from "@/utils/privilege-mixin";
import { timeToLang, isDataExist } from "@/utils/functions";

export default {
  mixins: [privilegeMixin],
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
    };
  },
  props: ["grants", "pagination"],
  methods: {
    setGrant(data) {
      this.$emit("setGrant", data);
    },
    timeToLang(time) {
      return timeToLang(time, this.language);
    },
    isDataExist,
  },
  computed: {},
  async created() {},
};
</script>
